<template>
  <div class="page">
    <kod-header :header="pageObject.headerObject" />

    <div v-if="submitted" class="container">
      <div class="card card-submitted">
        <img class="card-submitted__icons" src="./icons/ok.svg" alt="Hotovo" />
        <h2 class="medium-heading-text">{{ $t('global.titles.all_done') }}</h2>
        <p class="body-text mt-4 px-8">{{ $t('referral.descriptions.data_deleted_from_db') }}</p>
        <k-button href="/" class="mt-8">
          <span>{{ $t('navigation.home') }}</span>
        </k-button>
      </div>
    </div>

    <div v-if="loading" class="container">
      <main class="page-content card">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <v-skeleton-loader class="mt-6" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader class="mt-6" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader class="mt-6" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader class="mt-6" type="button"></v-skeleton-loader>
      </main>
    </div>

    <div v-if="error" class="container">
      <main class="page-content card">
        <div class="form-error-message">
          <p>{{ $t('referral.record_not_found') }}</p>
        </div>
      </main>
    </div>

    <div v-if="!submitted && interest && interest.candidate" class="container">
      <main class="page-content card">
        <h2 class="heading-text">{{ $t('referral.subtitles.information_about_you') }}</h2>
        <hr class="ruler" />

        <p class="title-text mt-6">{{ $t('global.inputs.firstname_surname') }}</p>
        <p>{{ interest.candidate.name }}</p>

        <p class="title-text mt-8">{{ $t('global.inputs.email') }}</p>
        <p>{{ interest.candidate.email }}</p>

        <p class="title-text mt-8">{{ $t('global.inputs.phone') }}</p>
        <p>{{ interest.candidate.phone }}</p>

        <p class="title-text mt-8">{{ $t('referral.inputs.current_position') }}</p>
        <p>{{ interest.candidate.currentPosition }}</p>

        <hr class="ruler mt-10" />
        <p class="mt-8">
          <strong>{{ interest.name }}</strong>
          ({{ interest.email }}) {{ $t('referral.descriptions.recommended_you') }}
        </p>
        <k-button class="btn-error mt-12" color="red" @click="removeMyData">
          <span>{{ $t('global.actions.delete') }}</span>
        </k-button>
      </main>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  mounted() {
    this.loading = true
    firebase
      .functions()
      .httpsCallable('getFormReferralApprovalData')({
        interestId: this.$route.params.id
      })
      .then(({ data }) => {
        this.interest = data
      })
      .catch(error => {
        this.error = true
        window.console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  },
  data() {
    return {
      loading: false,
      submitted: false,
      error: false,
      interest: null,
      pageObject: {
        headerObject: {
          heading: 'Osobné údaje',
          containerClass: 'standard',
          color: 'white',
          breadcrumbs: []
        }
      }
    }
  },
  methods: {
    removeMyData() {
      this.$store.dispatch('REFERRAL/REMOVE_CANDIDATE', this.$route.params.id)
      this.submitted = true
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  font-family: inherit;
  font-size: 1rem;
  color: $koderia-black;
  height: 100%;
  margin-bottom: 10rem;
}

.page-content {
  @include margin-top-multiplier(5);
  width: 100%;
  max-width: 51.5rem;
  margin: 0 auto;
}

.card {
  @include padding-top-multiplier(7);
  @include padding-bottom-multiplier(7);
  @include padding-left-multiplier(8);
  @include padding-right-multiplier(8);
}

.footer {
  @include padding-top-multiplier(12);
}

.card-submitted {
  @include margin-top-multiplier(5);
  @include margin-bottom-multiplier(24);
  @include padding-multiplier(5);
  max-width: 39rem;
  margin: 0 auto;
  text-align: center;
}

.card-submitted__icons {
  width: 4.5rem;
  height: 4.5rem;
}
</style>
